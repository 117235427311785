body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Heading-now';
  src: url(../public/fonts/HeadingNow-72Light.otf);
}

@font-face {
  font-family: 'The-Youngest';
  src: url(../public/fonts/The\ Youngest\ Serif\ Book.ttf);
}

::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(63, 63, 63, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: rgb(131, 130, 130);
  outline: 1px solid rgb(107, 128, 148);
  border-radius: 10px;


}
:root {
  --p-orange: #FF5139;
  --p-yellow:#FFA857;
  --p-black: #302B2B;
  --p-purple: #FF99FF;
  --p-red: #ff3b44;
}
button {
  background-color: #FF5139;
  border: #FF5139;
  border-radius: 25px;
  color: azure;
  font-size: 1.2rem;
  padding: 10px;
  transition: .3s ease;
  cursor: pointer;
  color: inherit;
}
a:visited {
  color: inherit;
}
.error {
  color: red;
  font-size: 1.2rem;
  margin: 0.5rem;
}
a{
  text-decoration: none;
  color: inherit;
}
